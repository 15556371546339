import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePage from '../components/HomePage'

export default class IndexPage extends React.Component {
  render () {
    const { location, data } = this.props

    return <HomePage location={location} data={data} />
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export const pageQuery = graphql`
  query IndexQuery {
    mostArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } category: { regex: "/^(?!.*(Home|Education)).*$/" } } }
    ) {
      ...SimpleArticle
    }
    latestInAuto: allMarkdownRemark(
      sort: {
        order: DESC
        fields: [frontmatter___date]
      }
      filter: {
        frontmatter: {
          templateKey: {eq: "blog-post"}
          category: {regex: "/^(?=.*(Auto|auto)).*$/"}
        }
      }
      limit: 1
    ) {
      ...LatestInAuto
    }
    simpleHomeArticles: allMarkdownRemark(
      sort: {
        order: DESC,
        fields: [frontmatter___date]
      }
      filter: {
        frontmatter: {
          templateKey: {eq: "blog-post"}
          category: {regex: "/^(?=.*(Home)).*$/"}
        }
      }
    ) {
      ...ArticleWithSnippet
      }
    simpleEducationArticles: allMarkdownRemark(
      sort: {
        order: DESC
        fields: [frontmatter___date]
      }
      filter: {
        frontmatter: {
          templateKey: {eq: "blog-post"}
          category: {regex: "/^(?=.*(Education)).*$/"}
        }
      }
    ) {
      ...ArticleWithSnippet
    }
  }
`
